<template>
    <div class="py-8 bg-gray-50 overflow-hidden lg:py-24">
        <div
            class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                width="404" height="784" fill="none" viewBox="0 0 404 784"
                aria-hidden="true">
                <defs>
                    <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0"
                        y="0" width="20" height="20"
                        patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4"
                            class="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="784"
                    fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div class="relative">
                <h2 id="anchor-analytics"
                    class="title-blob relative text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl mt-12 lg:mt-12">
                    Let your data do the talking</h2>
                <p
                    class="max-w-3xl mx-auto text-center text-xl text-gray-500 mt-12 lg:mt-4">
                    The benefits of using data to run your business are
                    numerous. You can better understand your customers, optimize
                    your advertising efforts, tailor your content, boost your
                    profits, and much more. </p>
            </div>

            <div
                class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div class="relative">


                    <dl class="mt-10 space-y-10">
                        <div class="relative">
                            <dt>
                                <div
                                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                                    <!-- Heroicon name: outline/globe-alt -->
                                    <svg class="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 24 24"
                                        stroke-width="2" stroke="currentColor"
                                        aria-hidden="true">
                                        <path stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                    </svg>
                                </div>
                                <p
                                    class="ml-16 text-lg leading-6 font-medium text-gray-900">
                                    See the bigger picture</p>
                            </dt>
                            <dd class="mt-2 ml-16 text-base text-gray-500">
                                Easily view the metrics you need for your
                                business. Custom-made dashboards allow you to
                                visualize pretty much anything from your
                                business.
                            </dd>
                        </div>
                        <div class="relative">
                            <dt>
                                <div
                                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                                    <!-- Heroicon name: outline/status-online -->
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-6" fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                                    </svg>
                                </div>
                                <p
                                    class="ml-16 text-lg leading-6 font-medium text-gray-900">
                                    Manage business risk</p>
                            </dt>
                            <dd class="mt-2 ml-16 text-base text-gray-500">
                                Anticipate risks by keeping track of critical
                                aspects of your business. Company expenses,
                                marketing costs, conversion rates, etc.
                            </dd>
                        </div>

                        <div class="relative">
                            <dt>
                                <div
                                    class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
                                    <!-- Heroicon name: outline/trending-up -->
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-6" fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                                    </svg>
                                </div>
                                <p
                                    class="ml-16 text-lg leading-6 font-medium text-gray-900">
                                    Boost your profits</p>
                            </dt>
                            <dd class="mt-2 ml-16 text-base text-gray-500">From
                                data to decisive action. Use the insights that
                                data gives you to make better, strategic
                                decisions and grow your business.
                            </dd>
                        </div>


                    </dl>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                    <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                        width="784" height="404" fill="none"
                        viewBox="0 0 784 404">
                        <defs>
                            <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                x="0" y="0" width="20" height="20"
                                patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4"
                                    class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="784" height="404"
                            fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                    </svg>
                    <img class="relative mx-auto rounded-xl shadow-xl"
                        width="490" src="@/assets/img/img-data-talking.jpg"
                        alt="">
                </div>
            </div>

            <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                width="404" height="784" fill="none" viewBox="0 0 404 784"
                aria-hidden="true">
                <defs>
                    <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0"
                        y="0" width="20" height="20"
                        patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4"
                            class="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="404" height="784"
                    fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>


        </div>
    </div>

</template>


<style scoped>
.blob h2 {
    /* position: relat */
    width: 100%;
    display: block;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
    font-size: 22px;
    line-height: 1.7;
    color: #102770;
    transform: translate3d(0, 0, 35px) perspective(100px);
    /* z-index: 10 !important; */

}

h2 {
    z-index: 10 !important;
}

h2:before {
    position: absolute;
    content: '';
    background: linear-gradient(217deg, #FACC15, #f7ecc2);
    width: 70px;
    height: 70px;
    display: block;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 6px 20px 0 rgba(110, 112, 16, 0.3);
    animation: border-transform 6s linear infinite;
    z-index: -1;
}

@keyframes border-transform {

    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}
</style>