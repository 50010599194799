<template>
    <!-- Home Page -->
    <div>

        <!-- Header -->
        <Header />

        <!-- Features -->
        <Features />

        <!-- What we do -->
        <Do />

        <!-- Take your data -->
        <Take />

        <!-- Sheets -->
        <Sheets />

        <!-- CTA -->
        <CTA />

        <!-- Footer -->
        <Footer />
    </div>
</template>

<script>

import Header from '@/components/sections/HomeHeader.vue'
import Features from '@/components/sections/HomeFeatures.vue'
import Do from '@/components/sections/HomeDo.vue'
import Take from '@/components/sections/HomeMobile.vue'
import CTA from '@/components/sections/HomeCTA.vue'
import Sheets from '@/components/sections/HomeSheets.vue'
import Footer from '@/components/sections/HomeFooter.vue'

export default {
    name: 'HomePage',
    components: {
        Header,
        Features,
        Do,
        Take,
        CTA,
        Sheets,
        Footer,
    }
}

</script>