<template>
    <div class="py-8 px-4 bg-gray-50">
        <div class="relative mt-12 sm:mt-16 lg:mt-24">
            <div
                class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div class="lg:col-start-2">
                    <h3
                        class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                        Take your dashboard with you
                    </h3>
                    <!-- <p class="mt-3 text-lg text-gray-500">Take your data
                            with you</p> -->

                    <dl class="mt-10 space-y-10">
                        <div class="relative">
                            <dt>
                                <p
                                    class="text-lg leading-6 font-medium text-gray-900">
                                    Mobile & Tablet</p>
                            </dt>
                            <dd class="mt-2 text-base text-gray-500">
                                Access your data quickly and from any
                                device. With our custom-made dashboards, you
                                can easily view your data from your phone or
                                tablet.</dd>
                        </div>


                    </dl>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                        width="784" height="404" fill="none"
                        viewBox="0 0 784 404" aria-hidden="true">
                        <defs>
                            <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                                x="0" y="0" width="20" height="20"
                                patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4"
                                    class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="784" height="404"
                            fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                    </svg>


                    <div class="relative mx-auto  ">
                        <div class="phone view_1 w-64"
                            id="phone_1 flex items-center">

                            <img src="@/assets/img/img-phone-top-bar.png"
                                alt="">
                            <img class="w-full"
                                src="@/assets/img/img-phone-stats.png" alt="">
                            <img class="w-full "
                                src="@/assets/img/img-phone-graph.png" alt="">

                            <img src="@/assets/img/img-phone-bottom-bar.png"
                                alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.phone.view_1 {
    transform: rotateX(50deg) rotateY(0deg) rotateZ(-50deg);
    box-shadow: -3px 3px 0 #BBB, -6px 6px 0 #BBB, -9px 9px 0 #BBB, -12px 12px 0 #BBB, -14px 10px 20px #666;
}

.phone {
    border: 40px solid #ddd;
    border-width: 8px 7px;
    border-radius: 40px;
    margin: 50px auto;
    overflow: hidden;
    transition: all 0.5s ease;
}

.phone iframe {
    border: 0;
    width: 100%;
    height: 100%;
}
</style> 