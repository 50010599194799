<template>
  <div>
    <!-- header -->
    <Nav />

    <!-- about -->
    <div class="bg-white">
      <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div class="space-y-5 sm:space-y-4">
            <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
              About Us
            </h2>
            <p class="text-xl text-gray-500">
              Welcome! Here's a quick about page to get to know Better Insights.
            </p>
          </div>
          <div class="lg:col-span-2">
            <ul
              role="list"
              class="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
            >
              <!-- Cris -->
              <!-- <li class="sm:py-8">
                                <div
                                    class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                                    <div
                                        class="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                        <img class="object-cover shadow-lg rounded-lg"
                                            src="@/assets/img/about-cris.png"
                                            alt="">
                                    </div>
                                    <div class="sm:col-span-2">
                                        <div class="space-y-4">
                                            <div
                                                class="text-lg leading-6 font-medium space-y-1">
                                                <h3>Cristian Bello</h3>
                                                <p class="text-indigo-600">
                                                    Co-Founder / Data</p>
                                            </div>
                                            <div
                                                class="text-lg text-gray-500 space-y-4">
                                                <p class="text-gray-500">
                                                    I’m Cristian. An
                                                    entrepreneur at heart. I’ve
                                                    been creating and running
                                                    businesses for the past 10
                                                    years.</p>


                                                <p>
                                                    Running a business is busy
                                                    and not easy. Unfortunately,
                                                    we often dont
                                                    realize the power of
                                                    business analytics. That was
                                                    me back in 2015. With my
                                                    previous business, I did not
                                                    prioritise business
                                                    analytics in the first
                                                    years, and I paid for it. I
                                                    would spend countless hours
                                                    every month on tedious work
                                                    making analyses and reports
                                                    of my business.
                                                </p>

                                                <p>
                                                    The moment I implemented
                                                    business dashboards to
                                                    measure the results of my
                                                    company everything changed.
                                                    Suddenly, I had plenty of
                                                    time to focus on growing my
                                                    business and investors could
                                                    see automated reports easily
                                                    and clearly.
                                                </p>

                                                <p>
                                                    Having my own business
                                                    analytics dashboard changed
                                                    my work as a manager upside
                                                    down. That company was
                                                    acquired in 2020.
                                                </p>

                                                <p>
                                                    With Beter
                                                    Insights, I want to help
                                                    other managers become
                                                    data-driven leaders using
                                                    the beauty of business
                                                    dashboards. Book a call and
                                                    let’s discuss how we can
                                                    help your business.
                                                </p>
                                            </div>
                                            <ul role="list"
                                                class="flex space-x-5">
                                                 <li>
                                                    <a href="#"
                                                        class="text-gray-400 hover:text-gray-500">
                                                        <span
                                                            class="sr-only">Twitter</span>
                                                        <svg class="w-5 h-5"
                                                            fill="currentColor"
                                                            viewBox="0 0 20 20"
                                                            aria-hidden="true">
                                                            <path
                                                                d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                                        </svg>
                                                    </a>
                                                </li> 
                                                <li>
                                                    <a href="https://www.linkedin.com/in/cristianbello/"
                                                        class="text-gray-400 hover:text-gray-500">
                                                        <span
                                                            class="sr-only">LinkedIn</span>
                                                        <svg class="w-5 h-5"
                                                            fill="currentColor"
                                                            viewBox="0 0 20 20"
                                                            aria-hidden="true">
                                                            <path
                                                                fill-rule="evenodd"
                                                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                                clip-rule="evenodd" />
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li> -->

              <!-- Tony -->

              <li class="sm:py-8">
                <div
                  class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0"
                >
                  <div
                    class="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4"
                  >
                    <img
                      class="object-cover shadow-lg rounded-lg"
                      src="@/assets/img/about-me.jpeg"
                      alt=""
                    />
                  </div>
                  <div class="sm:col-span-2">
                    <div class="space-y-4">
                      <div class="text-lg leading-6 font-medium space-y-1">
                        <h3>Tony Rositano</h3>
                        <p class="text-indigo-600">Co-Founder / Developer</p>
                      </div>
                      <div class="text-lg text-gray-500 space-y-4">
                        <p class="">
                          I’m Tony, half-English and half- Italian, produced in
                          France. .
                        </p>
                        <p>
                          After studying business at The Hague University, I
                          went on to co-found my first startup at the age of 25.
                          Joining the rockstart accelerator in 2014 (where I met
                          Cristian) and raising money through business angels, I
                          started my entrepreneurship journey.
                        </p>

                        <p>
                          After some time as a freelance developer and a big
                          American tech firm. I decided to dive back into
                          entrepreneurship.
                        </p>

                        <p>
                          My love for excel and numbers fueled my desire to code
                          6 years ago. Since then I’ve developed a number of
                          side projects for fun and clients.
                        </p>

                        <p>
                          In my spare time, you’ll find me doing sports,
                          snowboarding, coding and playing the drums.
                        </p>
                      </div>
                      <ul role="list" class="flex space-x-5">
                        <!-- <li>
                                                    <a href="#"
                                                        class="text-gray-400 hover:text-gray-500">
                                                        <span
                                                            class="sr-only">Twitter</span>
                                                        <svg class="w-5 h-5"
                                                            fill="currentColor"
                                                            viewBox="0 0 20 20"
                                                            aria-hidden="true">
                                                            <path
                                                                d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                                        </svg>
                                                    </a>
                                                </li> -->
                        <li>
                          <a
                            href="https://www.linkedin.com/in/tony-rositano/"
                            class="text-gray-400 hover:text-gray-500"
                          >
                            <span class="sr-only">LinkedIn</span>
                            <svg
                              class="w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/sections/SharedNav.vue";

export default {
  name: "AboutPage",
  components: {
    Nav,
  },
};
</script>
