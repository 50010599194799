<template>
    <div>
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div
            class="relative bg-gray-50 pt-16 overflow-hidden sm:pt-24 lg:pt-32">
            <div
                class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                <div>

                    <h2 id="anchor-integrations"
                        class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                        Tons of spreadsheets? No problem.</h2>
                    <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                        Do you spend countless hours on excel preparing reports
                        or trying to understand how your business is doing? All
                        this work can be automated saving you a lot of time. Our
                        dashboards connect directly to your excel files or other
                        tools and show you the data you need. No more wasted
                        time on excel. Spend your time growing your business.
                    </p>
                </div>
                <div class="mt-12 -mb-10 sm:-mb-24 lg:-mb-32">
                    <img class="lg:w-8/12	mx-auto"
                        src="@/assets/img/img-sheets-mini.png" alt="sheets">
                </div>
            </div>
        </div>

    </div>
</template>