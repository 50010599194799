// import Vue from 'vue';
// import Meta from 'vue-meta';

// Vue.use(Meta);

import { createRouter, createWebHistory } from 'vue-router';

import PageHome from '@/components/pages/HomePage';
import PageAbout from '@/components/pages/AboutPage';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: PageHome,
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: PageAbout,
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
