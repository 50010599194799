<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` :
        `SITE_NAME`
    }}</template>
  </metainfo>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  setup() {
    useMeta({
      title: 'Better Insights - Your data visualization partner',
      htmlAttrs: { lang: 'en', amp: true },
      description: 'We help you become a data-driven business. Make better decisions with beautiful custom-made dashboards. Save time and grow your business.'
    })

    // let route = this.$route.name;
    // console.log(route)
  }


}

</script>


<style>
#app {
  overflow: hidden;
}
</style>