<template>
    <div>
        <div class="bg-white">
            <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div
                    class="bg-yellow-400 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                    <div
                        class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div class="lg:self-center">
                            <h2
                                class="text-3xl font-extrabold text-white sm:text-4xl">
                                <span class="block">Ready to dive in? Get a free
                                    30min
                                    analytics audit</span>

                            </h2>
                            <p class="mt-4 text-lg leading-6 text-yellow-100">
                                Get a complete report of the state of your data
                                and actionable tips to improve the way your
                                business works with data.</p>
                            <a target="_blank"
                                href="https://meetings-eu1.hubspot.com/tony-rositano"
                                class="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-yellow-400 hover:bg-indigo-50">Dive
                                in</a>
                        </div>
                    </div>
                    <div
                        class="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                        <img class="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                            src="https://tailwindui.com/img/component-images/full-width-with-sidebar.jpg"
                            alt="App screenshot">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>